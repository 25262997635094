import React, { useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import PeopleSign from '../images/peoples_sign.svg'
import LinkedIn from '../images/LinkedinBlue.svg'
import Layout, { Block, LeadingMotto } from '../components/Layout'
import Seo from '../components/seo'

const Tag = ({ children }) => (
	<span className='text-base inline-flex whitespace-nowrap rounded border border-lightGray px-2.5 py-1.5 mr-2 mb-2 tracking-wider'>
		#{children}
	</span>
)

const PeopleItem = ({slug, name, photo, tags, linkedin, html, order, i,}) => {
	return (
		<div className='flex flex-wrap flex-row'>
			<div className={`w-full md:w-1/2 xl:w-1/3 mb-14 p-7`}>
				<img className='rounded-photo' src={photo} />
			</div>
			<div className={`w-full md:w-1/2 xl:w-2/3 mb-14 p-7`}>
				<div className='text-2xl font-bold font-serif leading-header mt-2'>
					{name}
					{linkedin ? (
						<span className='inline-block ml-3 h-7'>
							<a href={linkedin} target='_blank'>
								<LinkedIn className='inline h-full' />
							</a>
						</span>
					) : (
						''
					)}
				</div>
				<div className='mt-4'>
					{tags.map((t, i) => (
						<Tag key={`peoples-tag-${i}`}>{t}</Tag>
					))}
				</div>
				<div className='pb-5 2xl:pb-10 mt-7'>
					<div
						className='people-md pt-0 leading-header text-grey'
						dangerouslySetInnerHTML={{__html: html}}
					/>
				</div>
			</div>
		</div>
	)
}

export default function Peoples({ data }) {
	const peoples = data.allMarkdownRemark.nodes
	const title = `Phystech Ventures: People`

	useEffect(() => {
		document.title = title
	})

	return (
		<Layout>
			<Seo title={title} />
			<Block
				color='yellowGreen'
				className='pt-28 md:pt-48 pb-10 2xl:pt-72 2xl:pb-20'
				nestedClassName='p-7'
			>
				<LeadingMotto
					icon={<PeopleSign className='h-full' />}
					text={
						<div className='w-auto lg:w-1/2'>
							Driven by passion for science with a keen edge
						</div>
					}
				/>
			</Block>
			<Block
				color='white'
				className='pt-10 pb-20'
				nestedClassName='flex flex-wrap flex-col'
			>
				{peoples.map(({ frontmatter, html }, i) => (
					<PeopleItem
						key={`people-item-${frontmatter.slug}`}
						slug={frontmatter.slug}
						name={frontmatter.name}
						photo={frontmatter.photo}
						tags={frontmatter.tags}
						order={frontmatter.order}
						linkedin={frontmatter.linkedin}
						html={html}
						i={i + 1}
					/>
				))}
			</Block>
		</Layout>
	)
}

export const query = graphql`
	query {
		allMarkdownRemark(
			filter: {frontmatter: {slug: {regex: "/people/"}}}
			sort: {fields: frontmatter___order, order: ASC}
		) {
			nodes {
				frontmatter {
					order
					name
					slug
					photo
					tags
					linkedin
				}
				html
			}
		}
	}
`
